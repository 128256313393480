import React from 'react'
import styled from 'styled-components'

const H1 = styled.h1 `
    background: #0c0c0c;
    text-align: center;
    padding: 10px;
`

const Title = ({ text }) => (
    <H1 id="calc_title">{text}</H1>
);

export default Title;