import HC1 from './images/HC1.png'
import HC2 from './images/HC2.png'
import UC from './images/UC.png'
import UW from './images/UW.png'
import BC from './images/BC.png'
import USTC from './images/USTC.png'

export default function calcPaint(gain, zoomDisabled) {
    let paint = {
        title: "",
        description: "",
        alert: "",
        image: "",
    };

    // Обычная краска
    if (!zoomDisabled) {
        if (gain < 0.4) {
            paint.title = "Предупреждение";
            paint.alert = "Рекомендуем увеличить размер экрана, добавить фоновую засветку помещения или выбрать МЕНЕЕ яркий проектор.";
        }
        else if (gain < 0.5) {
            paint.title = "Black Contrast";
            paint.image = BC;
            paint.description = "BLACK CONTRAST – проекционная краска, позволяющая создать почти черные поверхности с уникальными " +
                "свойствами – высокого контраста проецируемого изображения даже в светлых помещениях или при наличии постоянной " +
                "фоновой засветки. Идеальна для создания огромных инсталляционных экранов на выставках или в музеях, с проекторами " +
                "высокой яркости."
            paint.alert = "Данная краска не предназначена для ночного просмотра и требует небольшой фоновой засветки помещения.";
        }
        else if (gain < 0.8) {
            paint.title = "Ultra Contrast";
            paint.image = UC;
            paint.description = "ULTRA CONTRAST – проекционная краска, позволяющая создать экран с уникальными свойствами – " +
                "высокого контраста и глубокого черного цвета даже в светлых помещениях или при наличии постоянной фоновой " +
                "засветки. Идеальна для создания огромных экранов в гостиных, спорт-барах или на выставках, с проекторами " +
                "повышенной яркости."
        } else if (gain < 0.9) {
            paint.title = "High Contrast 2";
            paint.image = HC2;
            paint.description = "HIGH CONTRAST – проекционная краска, позволяющая создавать качественные экраны с улучшенным " +
                "контрастом и глубиной черного, в условиях обычного светлого помещения. Состав содержит уникальные соотношения " +
                "светоотражающих и поглощающих пигментов для различных условий просмотра и проецирования. Прекрасно подходит " +
                "для домашних кинотеатров в гостиной.";

        } else if (gain < 1.0) {
            paint.title = "High Contrast 1";
            paint.image = HC1;
            paint.description = "HIGH CONTRAST – проекционная краска, позволяющая создавать качественные экраны с улучшенным " +
                "контрастом и глубиной черного, в условиях обычного светлого помещения. Состав содержит уникальные соотношения " +
                "светоотражающих и поглощающих пигментов для различных условий просмотра и проецирования. Прекрасно подходит " +
                "для домашних кинотеатров в гостиной.";
        } else if (gain <= 1.3) {
            paint.title = "Ultra White";
            paint.image = UW;
            paint.description = "ULTRA WHITE – проекционная краска, позволяющая создавать качественные экраны высокой яркости " +
                "изображения и максимальной цветовой корректности. Поверхность экрана обладает повышенной белизной и отражающими " +
                "свойствами. Прекрасно подходит для офисных помещений с фоновой засветкой или в домашних кинозалах с огромными экранами.";
        } else {
            paint.title = "Предупреждение";
            paint.alert = "Рекомендуем уменьшить размер экрана, улучшить условия затемнения помещения или выбрать БОЛЕЕ яркий проектор.";
        }

    // УКФ краска
    } else {
        if (gain < 0.5) {
            paint.title = "Предупреждение";
            paint.alert = "Рекомендуем увеличить размер экрана, добавить фоновую засветку помещения или выбрать МЕНЕЕ яркий проектор.";
        }
        else if (gain < 0.6) {
            paint.title = "Black Contrast";
            paint.image = BC;
            paint.description = "BLACK CONTRAST – проекционная краска, позволяющая создать почти черные поверхности с уникальными " +
                "свойствами – высокого контраста проецируемого изображения даже в светлых помещениях или при наличии постоянной " +
                "фоновой засветки. Идеальна для создания огромных инсталляционных экранов на выставках или в музеях, с проекторами " +
                "высокой яркости."
            paint.alert = "С учетом выбранных Вами условий мы не можем предложить вам продукт Ultra Short Throw Contrast, " +
                "так как изображение окажется слишком ярким";
        } else if (gain < 1.0) {
            paint.title = "Ultra Short Throw Contrast";
            paint.image = USTC;
            paint.description = "Уникальная проекционная краска для создания экранной поверхности для ультра-короткофокусных " +
                "проекторов. Наличие в составе светоотражающих и поглощающих пигментов позволяет усилить контраст и глубину " +
                "черного цвета, а рельеф поверхности обеспечивает лучшее отражение света проектора в сторону зрителей."
        } else if (gain <= 1.2) {
            paint.title = "High Contrast 1";
            paint.image = HC1;
            paint.description = "HIGH CONTRAST – проекционная краска, позволяющая создавать качественные экраны с улучшенным " +
                "контрастом и глубиной черного, в условиях обычного светлого помещения. Состав содержит уникальные соотношения " +
                "светоотражающих и поглощающих пигментов для различных условий просмотра и проецирования. Прекрасно подходит " +
                "для домашних кинотеатров в гостиной.";
        } else {
            paint.title = "Предупреждение";
            paint.alert = "Рекомендуем уменьшить размер экрана, улучшить условия затемнения помещения или выбрать БОЛЕЕ яркий проектор.";
        }
    }


    return paint;
}