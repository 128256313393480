import React from 'react'
import styled from 'styled-components'
import colors from '../../Components/Colors/Colors'
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper'
import NumberInput from '../../Components/NumberInput/NumberInput'

const Grid = styled.div `
    display: grid;
    column-gap: 10px;
    grid-template:
            "input label" 24px
            / 80px 200px;
`

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(value) {
        this.props.onChange(value);
        let block = document.getElementsByClassName("ansired")[0];
        if (value > 2800) {
            block.style.display = "block";
        }  else {
            block.style.display = "none";
        }
    }
    render() {
        return (
            <div id="calc_block4">
                <a name="block4" href="#block4" > </a>
                <Header link className="backBlue">3. ЯРКОСТЬ ПРОЕКТОРА</Header>
                <Helper>
                    Уточните в характеристиках вашего проектора его реальную яркость, измеряемую в ANSI люменах.
                </Helper>
                <Helper className="gray">
                    Китайские проекторы, к слову, указывают яркость, измеренную по собственным стандартам,<br/>
                    которая в несколько раз превышает стандартизированные показатели измерений ANSI.
                </Helper>
                <Helper className="ansired" id="ansi-warning">
                    Вы уверены что ввели яркость проектора в единицах ANSI Lm? Домашние проекторы обычно имеют реальную яркость ниже.
                </Helper>
                <Grid>
                    <NumberInput onChange={this.inputChange} />
                    <label style={{color: colors.yellow}}>ANSI Lm</label>
                </Grid>
            </div>
        );
    }
}