import React from 'react'


export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: undefined,
            width: undefined,
            zoom: 0.95, // среднее положение объектива
            zoomDisabled: undefined,
            ansi: undefined,
            aspect: undefined,
            mode: undefined,
            blackout: undefined,
            blackout2: undefined,
        }
        this.handleFocusChange = this.handleFocusChange.bind(this);
        this.handleWidthChange = this.handleWidthChange.bind(this);
        // this.handleZoomChange = this.handleZoomChange.bind(this);
        this.handleAnsiChange = this.handleAnsiChange.bind(this);
        this.handleAspectChange = this.handleAspectChange.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleBlackoutChange = this.handleBlackoutChange.bind(this);
    }
    log() {
        // console.log("main state: ", this.state);
    }
    handleFocusChange(focus) {
        if (Math.fround(focus) === Math.fround(1.1)) {
            this.setState({
                // zoom: 1.0,
                zoomDisabled: true,
                focus: focus
            });
        } else {
            this.setState({
                zoomDisabled: false,
                focus: focus
            });
        }
    }
    handleWidthChange(width) {
        this.setState({ width });
    }
    // handleZoomChange(zoom) {
    //     this.setState({ zoom });
    // }
    handleAnsiChange(ansi) {
        this.setState({ ansi });
    }
    handleAspectChange(aspect) {
        this.setState({ aspect });
    }
    handleModeChange(mode) {
        this.setState({ mode });
    }
    handleBlackoutChange({ value, value2 }) {
        this.setState({
            blackout: value,
            blackout2: value2
        });
    }
}