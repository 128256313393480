import React from 'react'
import styled from 'styled-components'
import Block1 from '../../Blocks/Block-1/Desktop'
import Block2 from '../../Blocks/Block-2/Desktop'
// import Block3 from '../../Blocks/Block-3/Desktop'
import Block4 from '../../Blocks/Block-4/Desktop'
import Block5 from '../../Blocks/Block-5/Desktop'
import Block6 from '../../Blocks/Block-6/Desktop'
import Block7 from '../../Blocks/Block-7/Desktop'
import Check from '../../Blocks/Check/Desktop'
import Title from '../Title/Desktop'
import Main from './Main'

const Div = styled.div`
    margin: auto;
    width: 1024px;
`

export default class MainDesktop extends Main {
    render() {
        return (
            <React.Fragment>
                <Title text="КАЛЬКУЛЯТОР подбора ПРОЕКЦИОННОЙ краски" />
                <Div>
                    <Block1 onChange={this.handleFocusChange} />
                    <Block2 onChange={this.handleWidthChange} />
                    <Block4 onChange={this.handleAnsiChange} />
                    {/* <Block3 onChange={this.handleZoomChange} disabled={this.state.zoomDisabled} /> */}
                    <Block5 onChange={this.handleAspectChange} />
                    <Block6 onChange={this.handleModeChange} />
                    <Block7 onChange={this.handleBlackoutChange} />
                    <Check  state={this.state} />
                </Div>
            </React.Fragment>
        )
    }
}