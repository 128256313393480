import React from 'react'
import './style.css'

export default class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.className = props.className;
        this.style = props.style;
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
        this.placeholder = props.placeholder;
    }
    handleChange(event) {
        let value = event.target.value.replace(',', '.');
        value = (value.match(/^\d+[.]{0,1}\d*$/g)) ? value : value.slice(0, value.length - 1);
        this.setState({ value });
        this.props.onChange(value);
    }
    render() {
        return (
            <input
                type="text"
                placeholder={this.placeholder}
                value={this.state.value}
                className={'calcInput ' + this.className}
                style={this.style}
                onChange={this.handleChange}
            />
        );
    }
}