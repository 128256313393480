import React from 'react'
import styled from 'styled-components'
import blackout1 from './images/blackout1.jpg'
import blackout2 from './images/blackout2.jpg'
import blackout3 from './images/blackout3.jpg'
import Radio from '../../Components/Radio/Radio'
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper'

const Grid = styled.div`
    display: grid;
    gap: 5px 10px;
    text-align: center;
    grid-template:
        "image1 image2 image3"
        "value1 value2 value3"
        "desc1  desc2  desc3"
        "radio1 radio2 radio3" 30px
        / 1fr 1fr 1fr;
`

const radio = [
    {
        label: "",
        value: 0.90,
        value2: 250,
        image: blackout1,
        desc: "Полное затемнение помещения, даже стен. Эффект КИНОЗАЛА."
    },
    {
        label: "",
        value: 0.45,
        value2: 320,
        image: blackout2,
        desc: "Светлые стены, возможно небольшая фоновая засветка. ДК в гостиной."
    },
    {
        label: "",
        value: 0.25,
        value2: 430,
        image: blackout3,
        desc: "Слабое затемнение - светлое помещение или локальный свет. Как правило, офис."
    },
];

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        let value2 = radio.filter(elem => Math.fround(elem.value) === Math.fround(value))[0].value2;
        this.props.onChange({value, value2});
    }
    render() {
        return (
            <div id="calc_block7">
                <a name="block7" href="#block7" > </a>
                <Header link className="backBlue">6. ЗАТЕМНЕНИЕ ВАШЕГО ПОМЕЩЕНИЯ</Header>
                <Helper>Укажите степень общего затемнения вашего помещения при использования проектора.</Helper>
                <Grid>
                    <Radio cn="block7" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        );
    }
}