import React from 'react'
import styled from 'styled-components'
import Radio from '../../Components/Radio/Radio'
import Image from '../../Components/Image/Image'
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper'
import imageSrc from './images/focus.jpg'

const Grid = styled.div`
    display: grid;
    gap: 5px 10px;
    text-align: center;
    grid-template:
        "image image"
        "value1 value2"
        "radio1 radio2" 30px
        / 1fr 1fr;
`

const radio = [
    { label: "ДЛИННО/СРЕДНЕФОКУСНЫЙ", value: 0.85 },
    // { label: "КОРОТКОФОКУСНЫЙ", value: 0.95 },
    { label: "УЛЬТРАКОРОТКОФОКУСНЫЙ", value: 1.1 },
];

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        this.props.onChange(value);
    }
    render() {
        return (
            <div id="calc_block1">
                <a name="block1" href="#block1" > </a>
                <Header link className="backBlue">1. ТИП ВАШЕГО ПРОЕКТОРА</Header>

                <Helper>
                    Каждый проектор предназначен для определенного расстояния проецирования - вблизи от экрана, или на определенном удалении.<br />
                    Укажите, к какой модели относится ваш проектор?
                </Helper>
                <Grid>
                    <Image style={{gridArea: 'image'}} src={imageSrc} />
                    <Radio cn="block1" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        )
    }
}