import React from 'react'
import styled from 'styled-components'
import location from './images/location.png'
import declOfNum from '../../Components/Length/Length'
import BackImage from '../../Components/Image/BackImage'
import Header from '../../Components/Header/Desktop'
import NumberInput from '../../Components/NumberInput/NumberInput'

const Grid = styled.div `
    display: grid;
    grid-template:
        "image"
        / 80%;
`
const screenDistance = {
    position: 'absolute',
    left: '41%',
    top: '32%',
    width: '70px'
}

const Text = styled.div `
    position: absolute;
    left: 52%;
    top: 33%;
    color: white;
`

export default class Location extends React.Component {
    constructor(props) {
        super(props);
        this.inputChange = this.inputChange.bind(this);
        this.state = { location: 0 };
    }
    inputChange(location) {
        this.setState({ location });
        this.props.onChange(location);
    }
    render() {
        return (
            <div id="calc_location">
                <Header className="backYellow">РАССТОЯНИЕ ДО ЭКРАНА</Header>
                <Grid>
                    <BackImage style={{gridArea: 'image'}} src={location}>
                        <NumberInput style={screenDistance} onChange={this.inputChange} />
                        <Text>{declOfNum(this.state.location)}</Text>
                    </BackImage>
                </Grid>
            </div>
        );
    }
}