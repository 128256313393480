import React from 'react'
import styled from 'styled-components'
import angleImage from './images/angle.png'
import BackImage from '../../Components/Image/BackImage'
import Image from '../../Components/Image/Image'
import Alert from '../../Components/Alert/Alert'
import calcDescription from './Description'

const Grid = styled.div `
    display: grid;
    grid-area: angleBody;
    gap: 0px 5px;
    grid-template:
        "backImage image"
        "text text"
        /3fr 1.3fr;
`
const StyledBackImage = styled.div `
    position: relative;
    grid-area: backImage;
    border: 1px solid white;
    
`
const StyledImage = styled.div `
    grid-area: image;
    justify-self: center;
    align-self: center;
`
const AngleValue = styled.div `
    position: absolute;
    left: 60%;
    top: 44%;
    width: 70px;
    color: yellow;
    text-align: center;
`
const Desc = styled.div `
    grid-area: text;
`

export default class Angle extends React.Component {
    calcAngle() {
        let width = this.props.state.width;
        let location = this.props.state.location;
        let angle_rad = Math.atan((width / 2.0) / location);
        let angle_deg = angle_rad * (180 / Math.PI);
        return parseInt(angle_deg * 2);
    }
    render() {
        let angle = this.calcAngle();
        let data = calcDescription(angle);
        return (
            <Grid>
                <StyledBackImage>
                    <BackImage src={angleImage} />
                    <AngleValue>{angle + "°"}</AngleValue>
                </StyledBackImage>
                <StyledImage>
                    <Image src={data.image1} height="auto" />
                </StyledImage>
                <Desc>
                    {data.text}
                    <Alert visible>{data.alert}</Alert>
                </Desc>
            </Grid>
        );
    }
}