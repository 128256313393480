import res2k from './images/2k.png'
import res4k from './images/4k.png'
import res480p from './images/480p.jpeg'
import res720p from './images/720p.png'
import res1080p from './images/1080p.png'

export default function calcRes(angle, res) {
    let result = {
        value: null,
        image: null,
        text: null,
        alert: null,
        ok: null,
    };
    let value = 0;
    switch (res) {
        case "480":
            result.image = res480p;
            value = (angle < 26) ? -1 : (angle >= 36) ? +1 : 0;
            break;
        case "720":
            result.image = res720p;
            value = (angle < 36) ? -1 : (angle > 44) ? +1 : 0;
            break;
        case "1080":
            result.image = res1080p;
            value = (angle > 53) ? +1 : 0;
            break;
        case "2k":
            result.image = res2k;
            value = (angle > 62) ? +1 : 0;
            break;
        case "4k":
            result.image = res4k;
            value = (angle > 62) ? +1 : 0;
            break;
        default: break;
    }
    if (angle > 62) {
        result.alert = "Не рекомендуется. Слишком близкое расстояние для просмотра, повышенная утомляемость.";
    } else if (value < 0) {
        result.text = "Выбранное разрешение проектора, с учетом размера вашего "
            + "экрана и расстояния до зрительского места - с избытком. Вы имеете возможность увеличить размер "
            + "экрана или сесть к нему ближе, не переживая за потерю целостности цифрового изображения.";
    } else if (value > 0) {
        result.alert = "Выбранное разрешение проектора, с учетом размера вашего экрана и расстояния до "
            + "зрительского места - слишком мало. Будут видны пиксели изображения.";
        result.text = "Предлагаем уменьшить размер экрана, отодвинуться от него, либо задуматься о замене проектора.";
    } else {
        result.text = "Всё отлично! Размер вашего экрана, расстояние до зрительского места и выбранное разрешение проектора ";
        result.ok = "позволяют получить качественное и слитное изображение без видимых пикселей.";
    }
    return result;
}