import React from 'react'
import styled from 'styled-components'
import sizeImage from './images/size.jpg'
import BackImage from '../../Components/Image/BackImage'

const Grid = styled.div`
    display: grid;
    font-size: 0.8em;
    grid-template:
        "image"
        / 1fr;
    grid-area: sizeBody;
`
const Height = styled.div`
    position: absolute;
    left: 3%;
    top: 43%;
    width: 50px;
    text-align: center;
    color: black !important;
    font-weight: bold;
`
const Width = styled.div`
    position: absolute;
    left: 43%;
    top: 83%;
    width: 50px;
    text-align: center;
    color: black !important;
    font-weight: bold;
`
const Diagonal = styled.div`
    position: absolute;
    left: 40%;
    top: 44%;
    width: 70px;
    text-align: center;
    color: black !important;
    font-weight: bold;
`
const Square = styled.div`
    position: absolute;
    left: 78%;
    top: 45%;
    border: 2px solid black;
    padding: 5px;
    width: 50px;
    text-align: center;
    color: black !important;
    font-weight: bold;
`

export default class Size extends React.Component {
    render() {
        let aspect = this.props.state.aspect;
        let width = this.props.state.width;
        let height = width / aspect;
        let diagonal = Math.sqrt(width * width + height * height) / 0.0254;
        let square = width * height;
        let heightText = parseFloat(height).toFixed(2) + " м";
        let widthText = parseFloat(width).toFixed(2) + " м";
        let diagonalText = parseFloat(diagonal).toFixed(2) + "\"";
        let squareText = parseFloat(square).toFixed(2) + " кв. м";
        return (
            <Grid>
                <BackImage src={sizeImage}>
                    <Height>
                        {"ВЫСОТА\n"} <span style={{ color: "blue" }}>{heightText}</span>
                    </Height>
                    <Width>
                        {"ШИРИНА\n"} <span style={{ color: "red" }}>{widthText}</span>
                    </Width>
                    <Diagonal>
                        {"ДИАГОНАЛЬ\n"} <span style={{ color: "green" }}>{diagonalText}</span>
                    </Diagonal>
                    <Square>
                        {"ПЛОЩАДЬ ЭКРАНА\n"} <span style={{ color: "black" }}>{squareText}</span>
                    </Square>
                </BackImage>
            </Grid>
        );
    }
}