const declOfNum = (n) => {
    let text_forms = ["литр", "литра", "литров"];
    n = Math.abs(n) % 100; var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 >= 2 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];
}

const calcVolume = (count) => {
    let volume = parseFloat(count).toFixed(1);
    let volWhole = parseInt(volume);
    let volPart = volume - volWhole;
    let text = null;
    if (volPart < 0.01) {
        volume = parseInt(volWhole);
        text = `${volume} ${declOfNum(volume)}`;
    } else if (volPart <= 0.5) {
        volume = parseFloat(volWhole + 0.5).toFixed(1);
        text = `${volume} литра`;
    } else {
        volume = parseInt(volWhole + 1.0);
        text = `${volume} ${declOfNum(volume)}`;
    }
    return text;
}

export default calcVolume;