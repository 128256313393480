import React from 'react'
import styled from 'styled-components';
import Image from '../Image/Image'
import colors from '../Colors/Colors';

const Label = styled.label `
    &:hover {
        cursor: pointer;
    }
    & img {
        box-shadow: 0px 0px 3px ${colors.white};
    }
    & img:hover {
        box-shadow: 0px 0px 3px ${colors.yellow};
    }
`

const RadioInput = styled.input.attrs(() => ({
    type: 'radio',
})) `
    cursor: pointer;
`

class Radio extends React.Component {
    constructor(props) {
        super(props);
        this.cn = this.props.cn;
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.props.onChange(e.target.value);
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.elements.map((elem, i) => (
                        <React.Fragment key={i}>

                            {/* Image */}
                            <div style={{gridArea: "image" + (i + 1)}}>
                                <Label htmlFor={this.cn + "_id-" + (i + 1)}>
                                    {(elem.image) ? <Image src={elem.image} alt={elem.label} height={elem.height} width={elem.width} /> : null}
                                </Label>
                            </div>

                            {/* Label */}
                            <div style={{gridArea: "value" + (i + 1)}}>
                                <Label htmlFor={this.cn + "_id-" + (i + 1)}>
                                    <b>{elem.label}</b>
                                </Label>
                            </div>

                            {/* Description */}
                            <div style={{gridArea: "desc" + (i + 1)}}>
                                <Label htmlFor={this.cn + "_id-" + (i + 1)}>
                                    {elem.desc}
                                </Label>
                            </div>

                            {/* Buttons */}
                            <div style={{gridArea: "radio" + (i + 1)}}>
                                <RadioInput
                                    name={this.cn}
                                    onChange={this.handleChange}
                                    id={this.cn + "_id-" + (i + 1)}
                                    value={elem.value}
                                    disabled={this.props.disabled}
                                />
                            </div>
                        </React.Fragment>
                    ))
                }
            </React.Fragment>
        )
    }
}

export default Radio;