import React from 'react'
import styled from 'styled-components'
import mode1 from './images/cinema.jpg'
import mode2 from './images/standard.jpg'
import mode3 from './images/dynamic.jpg'
import Radio from '../../Components/Radio/Radio'
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper'

const Grid = styled.div`
    display: grid;
    gap: 5px 10px;
    text-align: center;
    grid-template:
        "image1 image2 image3"
        "value1 value2 value3"
        "desc1  desc2  desc3"
        "radio1 radio2 radio3" 30px
        / 1fr 1fr 1fr;
`

const radio = [
    {
        label: "КИНО",
        value: 0.75,
        image: mode1,
        desc: "Режим точных цветов и глубины черного, но сильное снижение яркости проектора."
    },
    {
        label: "СТАНДАРТ",
        value: 1.0,
        image: mode2,
        desc: "Стандартная яркость проектора, контраста и цветов. Универсальный режим."
    },
    {
        label: "ДИНАМИЧЕСКИЙ",
        value: 1.1,
        image: mode3,
        desc: "Завышенные яркость изображения и контраст. Режим спортбара или офиса."
    },
];

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        this.props.onChange(value);
    }
    render() {
        return (
            <div id="calc_block6">
                <a name="block6" href="#block6" > </a>
                <Header link className="backBlue">5. РЕЖИМ РАБОТЫ ПРОЕКТОРА</Header>
                <Helper>Укажите основной режим работы проектора, исходя из предполагаемого использования.</Helper>
                <Grid>
                    <Radio cn="block6" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        );
    }
}