import React from 'react'
import styled from 'styled-components'
import blackout1 from './images/blackout1.jpg'
import blackout2 from './images/blackout2.jpg'
import blackout3 from './images/blackout3.jpg'
import Radio from '../../Components/Radio/RadioMobile'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'

const Grid = styled.div`
    display: grid;
    row-gap: 10px;
    text-align: center;
    grid-template:
        "value1"
        "image1"
        "desc1"
        "value2"
        "image2"
        "desc2"
        "value3"
        "image3"
        "desc3"
        / 1fr;
`

const radio = [
    {
        label: "Темное",
        value: 0.90,
        value2: 250,
        image: blackout1,
        desc: "Полное затемнение помещения, даже стен. Эффект КИНОЗАЛА."
    },
    {
        label: "Умеренное",
        value: 0.45,
        value2: 320,
        image: blackout2,
        desc: "Светлые стены, возможно небольшая фоновая засветка. ДК в гостиной."
    },
    {
        label: "Светлое",
        value: 0.25,
        value2: 430,
        image: blackout3,
        desc: "Слабое затемнение - светлое помещение или локальный свет. Как правило, офис."
    },
];

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        let value2 = radio.filter(elem => Math.fround(elem.value) === Math.fround(value))[0].value2;
        this.props.onChange({value, value2});
    }
    render() {
        return (
            <div id="calc_block7">
                <a name="block7" href="#block7" > </a>
                <Header link className="colorWhiteCenter">6. ЗАТЕМНЕНИЕ ВАШЕГО ПОМЕЩЕНИЯ</Header>
                <Helper>Укажите степень общего затемнения вашего помещения при использования проектора.</Helper>
                <Grid>
                    <Radio cn="block7" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        );
    }
}