import React from 'react'
import cn from 'classnames'
import './style.css'

const Alert = ({ children, visible, green }) => (
    <p className={cn("alert",
        { "alert-visible": visible },
        { "alert-green": green },
        { "alert-red": !green })}>
        {children}
    </p>
);

export default Alert;