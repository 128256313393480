import React from 'react'
import styled from 'styled-components'
import Radio from '../../Components/Radio/RadioMobile'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'
import imageLong from './images/focus_long.jpg'
import imageShort from './images/focus_short.jpg'
import imageUltraShort from './images/focus_ultrashort.jpg'

const Grid = styled.div`
    display: grid;
    row-gap: 10px;
    text-align: center;
    grid-template:
        "value1"
        "image1"
        "value2"
        "image2"
        / 1fr;
`

const radio = [
    { label: "ДЛИННО/СРЕДНЕФОКУСНЫЙ", value: 0.85, image: imageLong },
    // { label: "КОРОТКОФОКУСНЫЙ", value: 0.95, image: imageShort },
    { label: "УЛЬТРАКОРОТКОФОКУСНЫЙ", value: 1.1, image: imageUltraShort },
];

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        this.props.onChange(value);
    }
    render() {
        return (
            <div id="calc_block1">
                <a name="block1" href="#block1" > </a>
                <Header link className="colorWhiteCenter">1. ТИП ВАШЕГО ПРОЕКТОРА</Header>

                <Helper>
                    Каждый проектор предназначен для определенного расстояния проецирования - вблизи от экрана, или на определенном удалении.<br />
                    Укажите, к какой модели относится ваш проектор?
                </Helper>
                <Grid>
                    <Radio cn="block1" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        )
    }
}