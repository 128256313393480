import React from 'react'
import styled from 'styled-components'
import colors from '../../Components/Colors/Colors'
import calcVolume from '../../Components/Volume/Volume'
import wallImage from './images/wall.png'
import BackImage from '../../Components/Image/BackImage'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'
import NumberInput from '../../Components/NumberInput/NumberInput'

const Grid = styled.div`
    display: grid;
    gap: 10px 30px;
    grid-template:
        "text" 30px
        "image"
        "volume"
        / 1fr;
`
const StyledBackImage = styled.div`
    grid-area: image;
`
const wallHeight = {
    position: 'absolute',
    left: '70.3%',
    top: '41%',
    width: '50px'
}

const wallWidth = {
    position: 'absolute',
    left: '35%',
    top: '58%',
    width: '50px'
}

const Vol = styled.div`
    grid-area: volume;
    color: ${colors.yellow};
`
const Volume = styled.span`
    font-weight: bold;
    font-size: larger;
`

export default class Wall extends React.Component {
    constructor(props) {
        super(props);
        this.height = 0;
        this.width = 0;
        this.heightInputChange = this.heightInputChange.bind(this);
        this.widthInputChange = this.widthInputChange.bind(this);
        this.state = { wallSquare: 0 };
    }
    getSquare() {
        let square = this.height * this.width;
        this.setState({ wallSquare: square });
        return square;
    }
    heightInputChange(height) {
        this.height = height;
        this.props.onChange(this.getSquare());
    }
    widthInputChange(width) {
        this.width = width;
        this.props.onChange(this.getSquare());
    }
    render() {
        return (
            <div id="calc_wall">
                <Header className="colorBlueCenter">РАССЧИТАТЬ ОБЪЕМ ВЫБРАННОЙ КРАСКИ на всю стену?</Header>
                <Grid>
                    <Helper style={{ gridArea: 'text' }}>Укажите ширину и высоту вашей стены, в метрах:</Helper>
                    <StyledBackImage>
                        <BackImage src={wallImage} height="auto">
                            <NumberInput style={wallHeight} onChange={this.heightInputChange} placeholder="высота" />
                            <NumberInput style={wallWidth} onChange={this.widthInputChange} placeholder="ширина" />
                        </BackImage>
                    </StyledBackImage>
                    <Vol>Объем выбранной краски на всю стену: <Volume>{calcVolume(this.state.wallSquare / 4)}</Volume></Vol>
                </Grid>
            </div>
        );
    }
}