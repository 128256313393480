import React from 'react'
import styled from 'styled-components'
import colors from '../../Components/Colors/Colors'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'
import NumberInput from '../../Components/NumberInput/NumberInput'

const Grid = styled.div`
    display: grid;
    grid-template:
            ". input label ." 24px
            / 1fr auto auto 1fr;
`
const Number = styled.div`
    grid-area: input;
    justify-self: center;
`
const Label = styled.div`
    grid-area: label;
    align-self: center;
    margin-left: 10px;
`

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(value) {
        this.props.onChange(value);
        let block = document.getElementsByClassName("ansired")[0];
        if (value > 2800) {
            block.style.display = "block";
        }  else {
            block.style.display = "none";
        }
    }
    render() {
        return (
            <div id="calc_block4">
                <a name="block4" href="#block4" > </a>
                <Header link className="colorWhiteCenter">3. ЯРКОСТЬ ПРОЕКТОРА</Header>
                <Grid>
                    <Number>
                        <NumberInput onChange={this.inputChange} style={{width: 80}} />
                    </Number>
                    <Label>
                        <label style={{ color: colors.yellow }}>ANSI Lm</label>
                    </Label>
                </Grid>
                <Helper className="ansired" id="ansi-warning">
                    Вы уверены что ввели яркость проектора в единицах ANSI Lm? Домашние проекторы обычно имеют реальную яркость ниже.
                </Helper>
                <Helper>
                    Уточните в характеристиках вашего проектора его реальную яркость, измеряемую в ANSI люменах.
                </Helper>
                <Helper className="gray">
                    Китайские проекторы, к слову, указывают яркость, измеренную по собственным стандартам,<br />
                    которая в несколько раз превышает стандартизированные показатели измерений ANSI.
                </Helper>
            </div>
        );
    }
}