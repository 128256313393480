import React from 'react'
import styled from 'styled-components';

const P = styled.p `
    &.gray {
        color: gray;
        font-style: italic;
    }
    &.ansired {
        color: red;
        font-style: italic;
        display: none;
    }
    &.inline {
        margin: 0;
    }
`

const Helper = (props) => (
    <P className={props.className}> {props.children} </P>   
);

export default Helper;