import React from 'react'
import styled from 'styled-components'
import aspect1 from './images/2-35-1.jpg'
import aspect2 from './images/16-9.jpg'
import aspect3 from './images/4-3.jpg'
import Radio from '../../Components/Radio/RadioMobile'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'

const Grid = styled.div`
    display: grid;
    row-gap: 10px;
    text-align: center;
    grid-template:
        "value1"
        "image1"
        "desc1"
        "value2"
        "image2"
        "desc2"
        "value3"
        "image3"
        "desc3"
        / 1fr;
`

const radio = [
    {
        label: "2,35:1",
        value: 2.35 / 1,
        image: aspect1,
        desc: "Для фанатов домашнего КИНОЗАЛА без компромиссов - узкий киношный экран."
    },
    {
        label: "16:9",
        value: 16 / 9,
        image: aspect2,
        desc: "Стандартное соотношение сторон экрана телевизоров. Универсален для просмотра любого контента - фильмов, сериалов, Youtube."
    },
    {
        label: "4:3",
        value: 4 / 3,
        image: aspect3,
        desc: "Стандартное соотношение сторон для офисных проекторов и учебных классов."
    },
];

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        this.props.onChange(value);
    }
    render() {
        return (
            <div id="calc_block5">
                <a name="block5" href="#block5" > </a>
                <Header link className="colorWhiteCenter">4. СООТНОШЕНИЕ СТОРОН ЭКРАНА</Header>
                <Helper>Укажите ЭКРАН с каким соотношением сторон (ширины к высоте) вы планируете использовать.</Helper>
                <Grid>
                    <Radio cn="block5" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        );
    }
}