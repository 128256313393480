import React from 'react'
import styled from 'styled-components'
import resolution1 from './images/480p.jpeg'
import resolution2 from './images/720p.png'
import resolution3 from './images/1080p.png'
import resolution4 from './images/2k.png'
import resolution5 from './images/4k.png'
import Radio from '../../Components/Radio/RadioMobile'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'

const Grid = styled.div `
    display: grid;
    gap: 10px;
    grid-template:
        "image1 image2 image3"
        "image4 image5 ."
        / 1fr 1fr 1fr;
`

const radio = [
    { value: "480", image: resolution1  },
    { value: "720", image: resolution2  },
    { value: "1080", image: resolution3 },
    { value: "2k", image: resolution4  },
    { value: "4k", image: resolution5  },
];

export default class Resolution extends React.Component {
    constructor(props) {
        super(props);
        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(value) {
        this.props.onChange(value);
    }
    render() {
        return (
            <div id="calc_resolution">
                <Header className="colorYellowCenter">КАЧЕСТВО ИЗОБРАЖЕНИЯ ВАШЕГО ПРОЕКТОРА</Header>
                <Helper>Уточните в характеристиках вашего проектора его настоящее разрешение.</Helper>
                <Helper className="gray">Имейте в виду - в китайских проекторах зачастую указывается "Максимально поддерживаемое"
                    разрешение аппарата, которое выдается за реальное разрешение матрицы.</Helper>
                <Grid>
                    <Radio cn="resolution" onChange={this.radioChange} elements={radio} />
                </Grid>
            </div>
        );
    }
}