import React from 'react'

export default class BackImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: 'relative',
        };
    }
    onImgLoad({ target: img }) {
        this.setState({ height: img.offsetHeight });
    }
    render() {
        let width = (this.props.width) ? this.props.width : "100%";
        let height = (this.props.height) ? this.props.height : "100%";
        return (
            <div style={this.state}>
                <img src={this.props.src} onLoad={this.onImgLoad.bind(this)} alt={this.props.alt} height={height} width={width} />
                {this.props.children}
            </div>
        )
    }
}