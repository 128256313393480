import cinematiccolor from './images/cinematiccolor.png'
import dolbyvision from './images/dolbyvision.png'
import hdr from './images/hdr.png'
import imax from './images/imax.png'

export default function calcIll(state, square) {
    let ansi = state.ansi;
    let blackout = state.blackout;
    let focus = state.focus;
    let mode = state.mode;
    let zoom = state.zoom;
    let square_ft = square * 10.7639111056;
    let illuminate = (ansi / square_ft) * blackout * focus * mode * zoom;
    let result = {
        value: null,
        image: null,
        text: null,
        alert: null,
        ok: null,
    };
    result.value = illuminate.toFixed(0);
    if (illuminate < 12) {
        result.alert = "Показатель освещенности экрана ниже границы комфортного просмотра фильмов.";
        result.text = "Рекомендуется уменьшить размер экрана,  улучшить затемнение помещения, либо сменить проектор на более яркий.";
    } else if (illuminate < 16) {
        result.alert = "Показатель освещенности близок к нижней границе комфортного просмотра фильмов.";
        result.text = "Рекомендуется уменьшить размер экрана, либо улучшить затемнение помещения.";
    } else if (illuminate < 22) {
        result.image = cinematiccolor;
        result.text = "Хороший показатель освещенности, допускающий комфортный просмотр фильмов в современном HD формате цветопередачи REC.709.";
    } else if (illuminate < 28) {
        result.image = imax;
        result.text = "Отличный показатель, соответствующий освещенности экранов в кинозалах высшей категории - формата IMAX.";
    } else if (illuminate < 35) {
        result.image = dolbyvision;
        result.text = "Высокий показатель освещенности экрана, позволяющий полноценно использовать контент в формате Dolby Vision Cinema и 3D очках.";
    } else if (illuminate <= 42) {
        result.image = hdr;
        result.text = "Очень высокая расчетная освещенность экрана. Есть возможность полноценно использовать современный контент цветопередачи контента HDR High / Target.";
    } else {
        result.alert = "Очень высокий показатель освещенности экрана, способный вызвать дискомфорт при длительном просмотре.";
        result.text = "Рекомендуем перевести проектор в режим пониженной яркости, либо увеличить размер экрана.";
    }
    return result;
}