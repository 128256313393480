import React from 'react'
import styled from 'styled-components'
import colors from '../../Components/Colors/Colors'
import calcVolume from '../../Components/Volume/Volume'
import Primer from './images/Primer.png'
import Anza from './images/Anza.jpg'
import Header from '../../Components/Header/Mobile'
import Image from '../../Components/Image/Image'

const Grid = styled.div `
    display: grid;
    row-gap: 10px;
    text-align: center;
    grid-template:
        "title1"
        "image1"
        "desc1"
        "volume1"
        "title2"
        "image2"
        "desc2"
        / 1fr;
`
const PrimerTitle = styled.div `
    grid-area: title1;
    font-size: 1.2em;
    font-weight: bold;
`
const PrimerDesc = styled.div `
    grid-area: desc1;
    font-style: italic;
    text-align: left;
`
const Vol = styled.div `
    grid-area: volume1;
    color: ${colors.yellow};
    text-align: left;
`
const Volume = styled.span `
    font-weight: bold;
    font-size: larger;
`
const AnzaTitle = styled.div `
    grid-area: title2;
    font-size: 1.2em;
    font-weight: bold;
`
const AnzaDesc = styled.div `
    grid-area: desc2;
    font-style: italic;
    text-align: left;
`

export default class PrimerAnza extends React.Component {
    calcPrimer() {
        return {
            title: "Screen Primer",
            description: "PRIMER SCREEN – уникальный грунт для любых впитываемых поверхностей, помогающий подготовить их " +
                "для нанесения проекционных красок Wall Screen Paint. Не требует тщательной очистки стен после шпатлевания " +
                "и шкурения, имеет хорошую предварительную укрывистость, включая блокировку жирных пятен и влагоизоляцию.",
            alert: "",
            image: Primer,
        };
    }
    calcAnza() {
        return {
            title: "Инструменты для нанесения",
            description: "Долговечный валик c высокой производительностью и хорошей покрывающей способностью Шведского "
                + "производства. Для идеального нанесения проекционных красок предлагается специальная шубка из микрофибры "
                + "для супер-гладкой структуры покрытия.",
            alert: "",
            image: Anza,
        };
    }
    render() {
        let primer = this.calcPrimer();
        let anza = this.calcAnza();
        let square = (this.props.wallSquare > 0) ? this.props.wallSquare : this.props.square;
        return (
            <div id="calc_anza">
                <Header className='colorYellowCenter'>РЕКОМЕНДУЕМ ПРИОБРЕСТИ ДОПОЛНИТЕЛЬНО</Header>
                <Grid>
                    <Image style={{gridArea: 'image1'}} src={primer.image.valueOf()} width="50%" />
                    <PrimerTitle>{primer.title}</PrimerTitle>
                    <PrimerDesc>{primer.description}</PrimerDesc>
                    <Vol>Необходимый объем грунта: <Volume>{calcVolume(square / 4)}</Volume></Vol>
                    <Image style={{gridArea: 'image2'}} src={anza.image.valueOf()} width="50%" />
                    <AnzaTitle>{anza.title}</AnzaTitle>
                    <AnzaDesc>{anza.description}</AnzaDesc>
                </Grid>
            </div>
        );
    }
}