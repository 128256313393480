import React from 'react'

export default class Image extends React.Component {
    render() {
        let width = (this.props.width) ? this.props.width : "100%";
        let height = (this.props.height) ? this.props.height : "100%";
        return (
            <div style={this.props.style}>
                <img src = {this.props.src || 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='} onError = {e => e.target.style.display = 'none'} alt={this.props.alt} height={height} width={width} />
            </div>
        )
    }
}