import React from 'react'
import styled from 'styled-components';
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper';
import Paint from '../Paint/Desktop'
import Wall from '../Wall/Desktop'
import PrimerAnza from '../Primer-Anza/Desktop'
import Location from '../Location/Desktop'
import Resolution from '../Resolution/Desktop'
import Info from '../Info/Desktop'

const Grid = styled.div`
    display: grid;
    gap: 5px 10px;
    grid-template:
        "title title title"
        ". focus focus"
        ". width width"
        /* ". zoom zoom" */
        ". ansi ansi"
        ". aspect aspect"
        ". mode mode"
        ". blackout blackout"
        / 33% auto 33%;
`
const Title = () => (
    <div style={{ gridArea: 'title' }}>
        <Header className="colorRedCenter">Не удается подобрать краску по следующим причинам:</Header>
    </div>
)
const Focus = () => (
    <div style={{ gridArea: 'focus' }}>
        Не выбран тип проектора в <a href="#block1">пункте 1</a>
    </div>
)
const Width = () => (
    <div style={{ gridArea: 'width' }}>
        Не указан размер экрана в <a href="#block2">пункте 2</a>
    </div>
)
const Ansi = () => (
    <div style={{ gridArea: 'ansi' }}>
        Не указана яркость проектора в <a href="#block4">пункте 3</a>
    </div>
)
// const Zoom = () => (
//     <div style={{ gridArea: 'zoom' }}>
//         Не выбрано положение объектива проектора в <a href="#block3">пункте 4</a>
//     </div>
// )
const Aspect = () => (
    <div style={{ gridArea: 'aspect' }}>
        Не выбрано соотношение сторон экрана в <a href="#block5">пункте 4</a>
    </div>
)
const Mode = () => (
    <div style={{ gridArea: 'mode' }}>
        Не выбран режим просмотра в <a href="#block6">пункте 5</a>
    </div>
)
const Blackout = () => (
    <div style={{ gridArea: 'blackout' }}>
        Не выбран режим затемнения помещения в <a href="#block7">пункте 6</a>
    </div>
)

export default class Check extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wallSquare: 0,
            location: undefined,
            resolution: undefined,
        }
        this.handleWallChange = this.handleWallChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleResolutionChange = this.handleResolutionChange.bind(this);
    }
    calc() {
        this.st = this.props.state;
        let result = 1;
        Object.values(this.st).forEach((value) => {
            result *= 'boolean' === typeof value ? 1 : value;
        });
        if (isNaN(result) || (result === 0)) {
            return false;
        } else {
            return true;
        }
    }
    calcSquare() {
        let height = this.st.width / this.st.aspect;
        let square = this.st.width * height;
        return square;
    }
    handleWallChange(wallSquare) {
        this.setState({ wallSquare });
    }
    handleLocationChange(location) {
        this.setState({ location });
    }
    handleResolutionChange(resolution) {
        this.setState({ resolution });
    }
    render() {
        if (this.calc()) {
            let allState = {...this.props.state, ...this.state};
            return (
                <div id="calc_cinema_quality">
                    <Paint state={this.props.state} />
                    <Wall onChange={this.handleWallChange} />
                    <PrimerAnza square={this.calcSquare()} wallSquare={this.state.wallSquare} />
                    <Header className="backBlueCenter">ХОТИТЕ ОЦЕНИТЬ КАЧЕСТВО ВАШЕГО ДОМАШНЕГО КИНОТЕАТРА?</Header>
                    <Helper>Добавьте несколько дополнительных параметров:</Helper>
                    <Location onChange={this.handleLocationChange} />
                    <Resolution onChange={this.handleResolutionChange} />
                    <Info state={allState} square={this.calcSquare()} />
                </div>
            )
        } else {
            return (
                <Grid>
                    <Title />
                    {(this.st.focus === undefined || this.st.focus <= 0) ? <Focus /> : null}
                    {(this.st.width === undefined || this.st.width <= 0) ? <Width /> : null}
                    {(this.st.ansi === undefined || this.st.ansi <= 0) ? <Ansi /> : null}
                    {/* {(this.st.zoom === undefined || this.st.zoom <= 0) ? <Zoom /> : null} */}
                    {(this.st.aspect === undefined || this.st.aspect <= 0) ? <Aspect /> : null}
                    {(this.st.mode === undefined || this.st.mode <= 0) ? <Mode /> : null}
                    {(this.st.blackout === undefined || this.st.blackout <= 0) ? <Blackout /> : null}
                </Grid>
            )
        }
    }
}