import React from 'react'
import styled from 'styled-components'
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper'
import Size from '../Size/Desktop'
import Angle from '../Angle/Desktop'
import Quality from '../Quality/Desktop'

const Grid = styled.div `
    display: grid;
    grid-template:
        ". sizeHeader ."
        ". sizeBody ."
        ". angleHeader ."
        ". angleBody ."
        ". qualityHeader ."
        ". qualityBody ."
        /20%    60%   20%;
    & > div {
        padding: 5px;
        border: 1px solid white;
    }
`
const SizeHeader = styled(Header) `
    grid-area: sizeHeader;
`
const SizeBody = styled(Size) `
`
const AngleHeader = styled(Header) `
    grid-area: angleHeader;
`
const AngleBody = styled(Angle) `
`
const QualityHeader = styled(Header) `
    grid-area: qualityHeader;
`
const QualityBody = styled(Quality) `
`

export default class Info extends React.Component {
    render() {
        let state = this.props.state;
        if (state.location !== undefined && state.resolution !== undefined) {
            return (
                <div id="calc_info">
                    <Header className="colorWhiteCenter"><i>Готово!</i></Header>
                    <Header className="colorBlueCenter">ОЦЕНИТЕ РАСЧЁТНЫЕ ДАННЫЕ по ВАШЕМУ ПРОЕКЦИОННОМУ ИЗОБРАЖЕНИЮ:</Header>
                    <Grid>
                        <SizeHeader className="colorYellowCenter">РАЗМЕР И ПЛОЩАДЬ ЭКРАНА</SizeHeader>
                        <SizeBody state={state} />
                        <AngleHeader className="colorYellowCenter">КОМФОРТНОСТЬ ПРОСМОТРА</AngleHeader>
                        <AngleBody state={state} />
                        <QualityHeader className="colorYellowCenter">КАЧЕСТВО ПРОЕКЦИОННОГО ИЗОБРАЖЕНИЯ</QualityHeader>
                        <QualityBody state={state} square={this.props.square} />
                    </Grid>
                    <Header className="colorBlueCenter">
                        ВЫ ИМЕЕТЕ ВОЗМОЖНОСТЬ ВНОСИТЬ ЛЮБЫЕ ИЗМЕНЕНИЯ в ВВЕДЁННЫЕ ДАННЫЕ,<br/>ЧТОБЫ ДОБИТЬСЯ ОПТИМАЛЬНОГО РЕЗУЛЬТАТА
                    </Header>
                    <Helper className="gray">Вернитесь вверх по странице и измените свой выбор или значение, введенное цифрами, чтобы увидеть изменения в расчётных результатах.</Helper>
                </div>
            )
        } else {
            return null;
        }
    }
}