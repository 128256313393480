import twentyfox from './images/20th.png'
import imax from './images/imax.png'
import smpte from './images/smpte.png'
import thx from './images/thx.jpg'
import smptethx from './images/smpte-thx.png'

export default function calcDescription(angle) {
    let result = {
        image1: null,
        text: null,
        alert: null,
    };
    if (angle <= 26) {
        result.alert = "Угол просмотра слишком узкий для погружения зрителя в происходящее на экране. Попробуйте увеличить " +
            "его размер или придвинуть места просмотра ближе.";
    } else if (angle <= 36) {
        result.text = "Допустимо по стандартам SMPTE для дальних рядов кинотеатра. "
            + "В этой связи, вы получите посредственное погружение в кинокартину. Попробуйте сесть ближе, либо увеличить " +
            "размер экрана.";
        result.image1 = smpte;
    } else if (angle <= 44) {
        result.text = "Середина коммерческого кинозала по стандартам THX от Lucasfilm. Хорошее погружение зрителя в картину.";
        result.image1 = thx;
    } else if (angle <= 46) {
        result.text = "Лучшее место просмотра по мнению кинокомпании 20th FOX.";
        result.image1 = twentyfox;
    } else if (angle <= 52) {
        result.text = "Лучшие места в кинозале по стандартам SMPTE и THX от Lucasfilm.";
        result.image1 = smptethx;
    } else if (angle <= 53) {
        result.text = "Идеальное место просмотра по стандарту THX от Lucasfilm и киностудий звукозаписи.";
        result.image1 = thx;
    } else if (angle <= 62) {
        result.text = "Отличное место для любителей полного погружения в кино в стиле IMAX.Первые ряды кинозалов.";
        result.image1 = imax;
    } else {
        result.alert = "Угол просмотра слишком большой для комфортного восприятия кинокартины.Попробуйте отодвинуться от " +
            "экрана или уменьшить его размер.";
    }
    return result;
}