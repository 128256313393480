import React from 'react'
import styled from 'styled-components'
import Image from '../../Components/Image/Image'
import Alert from '../../Components/Alert/Alert'
import Helper from '../../Components/Helper/Helper'
import calcRes from './Res'
import calcIll from './Ill'

const Grid = styled.div`
    display: grid;
    row-gap: 5px;
    grid-area: qualityBody;
    grid-template:
        "res"
        "ill";
`
const GridRes = styled.div`
    display: grid;
    grid-area: res;
    padding: 10px;
    border: 1px solid white;
    column-gap: 10px;
    grid-template:
            "image text"
            /1fr 3fr;
`
const ResImage = styled.div`
    grid-area: image;
    align-self: center;
    justify-self: center;
`
const GridIll = styled.div`
    display: grid;
    grid-area: ill;
    padding: 10px;
    border: 1px solid white;
    column-gap: 10px;
    grid-template:
        "image text"
        /1fr 3fr;
`
const IllImage = styled.div`
    grid-area: image;
    align-self: center;
    justify-self: center;
`

export default class Quality extends React.Component {
    calcAngle() {
        let width = this.props.state.width;
        let location = this.props.state.location;
        let angle_rad = Math.atan((width / 2.0) / location);
        let angle_deg = angle_rad * (180 / Math.PI);
        return parseInt(angle_deg * 2);
    }
    render() {
        let angle = this.calcAngle();
        let res = calcRes(angle, this.props.state.resolution);
        let ill = calcIll(this.props.state, this.props.square);
        return (
            <Grid>
                <GridRes>
                    <ResImage>
                        <Image src={res.image} />
                    </ResImage>
                    <div style={{gridArea: 'text'}}>
                        <Alert visible>{res.alert}</Alert>
                        {res.text}
                        <Alert visible green>{res.ok}</Alert>
                    </div>
                </GridRes>
                <GridIll>
                    <IllImage>
                        <Image src={ill.image} />
                    </IllImage>
                    <div style={{gridArea: 'text'}}>
                        Расчетная освещенность экрана в выбранных вами условиях составляет {ill.value} fL&nbsp;
                        <Helper className="gray inline">
                            при минимально допустимом значении 12 fL.
                        </Helper>
                        <Alert visible>{ill.alert}</Alert>
                        {ill.text}
                        <Alert visible green>{ill.ok}</Alert>
                    </div>
                </GridIll>
            </Grid>
        );
    }
}