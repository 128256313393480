import React from 'react'
import MediaQuery from 'react-responsive'
import { render } from 'react-dom'
import MainDesktop from './Parts/Main/Desktop'
import MainMobile from './Parts/Main/Mobile'
import './index.css'

render(
    <React.StrictMode>
        <MediaQuery minWidth={768}>
            <MainDesktop />
        </MediaQuery>
        <MediaQuery maxWidth={769}>
            <MainMobile />
        </MediaQuery>
    </React.StrictMode>,
    document.getElementById('erw-root')
);
