import React from 'react'
import styled from 'styled-components'
import colors from '../../Components/Colors/Colors'
import calcPaint from './Paints'
import calcVolume from '../../Components/Volume/Volume'
import Header from '../../Components/Header/Desktop'
import Image from '../../Components/Image/Image'
import Alert from '../../Components/Alert/Alert'

const Grid = styled.div `
    display: grid;
    gap: 10px 30px;
    grid-template:
        "image title param"
        "image desc desc"
        "image volume volume"
        / 1fr 3fr auto;
`
const Title = styled.div `
    grid-area: title;
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
`
const Desc = styled.div `
    grid-area: desc;
    font-style: italic;
`
const Vol = styled.div `
    grid-area: volume;
    color: ${colors.yellow};
`
const Volume = styled.span `
    font-weight: bold;
    font-size: larger;
`
const Params = styled.div `
    grid-area: param;
    color: ${colors.blue};
    border: 1px solid ${colors.yellow};
    border-radius: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
`
const ParamsValues = styled.div `
    position: absolute;
    background: black;
    font-style: normal;
    display: none;
    border: 1px solid ${colors.yellow};
    border-radius: 4px;
    padding: 10px;
`



export default class Paint extends React.Component {
    calcGain(st) {
        let height = st.width / st.aspect;
        let square = st.width * height;
        this.square = square;
        let gainValue = (square * st.blackout2) / (st.ansi * st.focus * st.mode * st.zoom);
        let gain = parseFloat(gainValue).toFixed(1);
        // if (gain < 1.0)
        //     gain = (gain < gainValue) ? 1.0 * gain + 0.1 : gain;
        // if (gain > 1.0)
        //     gain = (gain > gainValue) ? 1.0 * gain - 0.1 : gain;
        return gain;
    }
    paramsShow() {
        let block = document.getElementById("params-values");
        if (block.style.display === "none") {
            block.style.display = "block";
          } else {
            block.style.display = "none";
          }
    }
    render() {
        let st = this.props.state;
        let gain = this.calcGain(st);
        let paint = calcPaint(gain, st.zoomDisabled);
        return (
            <div id="calc_volume">
                <a name="paint" href="#paint" > </a>
                <Header className="backYellowCenter">РЕКОМЕНДУЕМАЯ ПРОЕКЦИОННАЯ КРАСКА</Header>
                <Grid>
                    <Image style={{gridArea: 'image'}} src={paint.image.valueOf()} />
                    <Title>{paint.title}</Title>
                    {/* <Params onClick={this.paramsShow}>i</Params> */}
                    <Desc>
                        {/* <ParamsValues id="params-values">
                            <p>Выбранные параметры:</p>
                            <ol>
                                <li>Тип проектора: { st.focus }</li>
                                <li>Ширина экрана: { st.width }</li>
                                <li>Яркость проектора: { st.ansi }</li>
                                <li>Соотношение сторон экрана: { parseFloat(st.aspect).toFixed(3) }</li>
                                <li>Режим работы проектора: { st.mode }</li>
                                <li>Затемнение помещения: { st.blackout } ( {st.blackout2} )</li>
                            </ol>
                        </ParamsValues> */}
                        {paint.description}
                        <Alert visible>{paint.alert}</Alert>
                    </Desc>
                    <Vol>Объем краски, необходимый для вашего экрана: <Volume>{ calcVolume(this.square / 4) }</Volume></Vol>
                </Grid>
            </div>
        );
    }
}