import React from 'react'
import styled from 'styled-components';
import colors from '../Colors/Colors';

const H2 = styled.h2`
    padding: 8px 14px;
    font-weight: 'bold';
    font-size: 140%;
    color: black;

    &.backBlue {
        /* background: linear-gradient(135deg, ${colors.blue} 90%, transparent 90.2%); */
        background: linear-gradient(90deg, ${colors.blue} 0%, ${colors.blue} 50%, transparent 100%);
        border-radius: 2px;
        margin-top: 20px;
    }
    &.backYellow {
        /* background: linear-gradient(135deg, ${colors.yellow} 90%, transparent 90.2%); */
        background: linear-gradient(90deg, ${colors.yellow} 0%, ${colors.yellow} 50%, transparent 100%);
        border-radius: 2px;
        margin-top: 20px;
    }
    &.backBlueCenter {
        margin-top: 60px;
        background: linear-gradient(90deg, transparent 0%, ${colors.blue} 20%, ${colors.blue} 80%, transparent 100%);
        text-align: center;
        border-radius: 2px;
    }
    &.backYellowCenter {
        margin-top: 60px;
        background: linear-gradient(90deg, transparent 0%, ${colors.yellow} 20%, ${colors.yellow} 80%, transparent 100%);
        text-align: center;
        border-radius: 2px;
    }
    &.colorBlueCenter {
        background: none;
        text-align: center;
        color: ${colors.blue};
    }
    &.colorYellowCenter {
        background: none;
        text-align: center;
        color: ${colors.yellow};
    }
    &.colorRedCenter {
        background: none;
        text-align: center;
        color: red;
        margin: 20px 0 0 0;
    }
    &.colorWhiteCenter {
        background: none;
        text-align: center;
        color: white;
        margin: 20px 0 0 0;
    }
`

const Header = (props) => {
    return (
        <div id="calc_header">
            <H2 className={props.className}>
                {props.children}
            </H2>
        </div>
    )
}

export default Header;