import React from 'react'
import styled from 'styled-components'
import colors from '../../Components/Colors/Colors'
import imageSrc from './images/width.jpg'
import declOfNum from '../../Components/Length/Length'
import BackImage from '../../Components/Image/BackImage'
import Header from '../../Components/Header/Desktop'
import Helper from '../../Components/Helper/Helper'
import NumberInput from '../../Components/NumberInput/NumberInput'

const Grid = styled.div`
    display: grid;
    grid-template:
        ". image . "
        / auto 80% auto;
`
const StyledBackImage = styled.div`
    grid-area: image;
`
// const NumberInputPos = styled(NumberInput)`
//     left: 40%;
//     top: 150px;
//     width: 70px;
//     position: absolute;
// `

const screenWidth = {
    left: '40%',
    top: '150px',
    width: '70px',
    position: 'absolute'    
}


// const TextPos = styled.div`
//     left: 50%;
//     top: 150px;
//     color: black;
//     position: absolute;
// `

const screenWidthLabel = {
    left: '50%',
    top: '150px',
    color: 'black',
    position: 'absolute'   
}

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.inputChange = this.inputChange.bind(this);
        this.state = { width: 0 };
    }
    inputChange(width) {
        this.setState({ width });
        this.props.onChange(width);
    }
    render() {
        return (
            <div id="calc_block2">
                <a name="block2" href="#block"> </a>
                <Header link className="backBlue">2. ШИРИНА ЭКРАНА</Header>
                <Helper>
                    Наверняка вы уже знаете какой ширины экран сможете себе позволить.<br />
                    Укажите ШИРИНУ вашего будущего экрана (через точку).<br />
                </Helper>
                <Helper className="gray">
                    При отсутствии Инструкции к проектору вы можете уточнить все проекционные расстояния и размеры будущего экрана&nbsp;
                    <a href="https://www.allprojectors.ru/ap_module/projectors/calculator">по этой ссылке</a>&nbsp;
                    или на сайте производителя выбранного проектора.
                </Helper>
                <Grid>
                    <StyledBackImage>
                        <BackImage src={imageSrc}>
                            <NumberInput style={screenWidth} onChange={this.inputChange} />
                            <div style={screenWidthLabel}>{declOfNum(this.state.width)}</div>
                        </BackImage>
                    </StyledBackImage>
                    <div></div>
                </Grid>
            </div>
        );
    }
}