import React from 'react'
import styled from 'styled-components'
import imageSrc from './images/width-mobile.jpg'
import declOfNum from '../../Components/Length/Length'
import BackImage from '../../Components/Image/BackImage'
import Header from '../../Components/Header/Mobile'
import Helper from '../../Components/Helper/Helper'
import NumberInput from '../../Components/NumberInput/NumberInput'

const Grid = styled.div`
    display: grid;
    grid-template-rows: 23% auto;
    grid-template-columns: 1fr 1fr 1fr;
`
const StyledBackImage = styled.div`
    grid-area: 1 / 1 / 3 / 4;
    z-index: 1;
`
const screenWidth = {
    gridArea: '2 / 1 / 3 / 4',
    zIndex: '2',
    alignSelf: 'flex-start',
    justifySelf: 'center',
    textAlign: 'center',    
    width: '100px',
    height: '26px'
}

const screenWidthLabel = {
    gridArea: '2 / 3 / 3 / 4',
    zIndex: '1',
    alignSelf: 'flex-start',
    justifySelf: 'left',
    textAlign: 'center',    
    color: 'white',
    marginTop: '8px'
}

export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.inputChange = this.inputChange.bind(this);
        this.state = { width: 0 };
    }
    inputChange(width) {
        this.setState({ width });
        this.props.onChange(width);
    }
    render() {
        return (
            <div id="calc_block2">
                <a name="block2" href="#block"> </a>
                <Header link className="colorWhiteCenter">2. ШИРИНА ЭКРАНА</Header>
                <Helper>
                    Наверняка вы уже знаете какой ширины экран сможете себе позволить.<br />
                    Укажите ШИРИНУ вашего будущего экрана (через точку).<br />
                </Helper>
                <Helper className="gray">
                    При отсутствии Инструкции к проектору вы можете уточнить все проекционные расстояния и размеры будущего экрана&nbsp;
                    <a href="https://www.allprojectors.ru/ap_module/projectors/calculator">по этой ссылке</a>&nbsp;
                    или на сайте производителя выбранного проектора.
                </Helper>
                <Grid>
                    <StyledBackImage>
                        <BackImage src={imageSrc} />
                    </StyledBackImage>
                    <NumberInput style={screenWidth} onChange={this.inputChange} />
                    <div style={screenWidthLabel}>{declOfNum(this.state.width)}</div>
                </Grid>
            </div>
        );
    }
}